import React from "react";
import styled from "styled-components";
import Typography from "@material-ui/core/Typography";

import RegisterForm from "./form/RegisterForm";
import Users from "./users/Users";
import Functions from "./functions/Functions";
import Video from "./video/Video";
import { createMuiTheme } from "@material-ui/core/styles";
import deepOrange from "@material-ui/core/colors/deepOrange";
import image1 from "../../static/images/banner2.jpg";
import ContentCuenta from "../../components/cuenta/cuentaRegresiva.js";
import Button from "@material-ui/core/Button";
import { Link } from "react-scroll";
import "../../App.css";

const Layout = styled.div`
  width: 100%;
  text-align: center;
`;

const Bold = styled.b`
  font-weight: 600;
  font-size: 95%;
  font-family: "Roboto";
`;
const Standar = styled.b`
  font-weight: 500;
  font-size: 95%;
`;
const Registro = styled.b`
  font-weight: 500;
  color: #1976d2;
`;
const Wrapper = styled.div`
  width: 100%;
  margin-top: 3rem;
  margin-bottom: 3rem;
`;
const Image = styled.img`
  width: 100%;
  height: auto;
  margin-bottom: 1rem;
`;

const Home = () => {
  // eslint-disable-next-line no-unused-vars
  const theme = createMuiTheme({
    palette: {
      primary: deepOrange,
    },
  });
  return (
    <>
      <Layout>
     
      <div id="seccion1"></div>
        <ContentCuenta />
        <Typography variant="h5" gutterBottom>
          <Registro  style={{color: "#183880"}}>Regístrate (evento gratuito)</Registro>
        </Typography>

        <RegisterForm />


        <Typography
          color="textPrimary"
          variant="h4"
          component="h1"
          gutterBottom
        >
          <Bold>
            <br />
            EL EVENTO ANUAL DE INNOVACIÓN
            <br />
            Y TECNOLOGÍA SOLAR PARA LA
            <br />
            INDUSTRIA EN MÉXICO.
          </Bold>
        </Typography>
        <Typography color="textSecondary" variant="h6" gutterBottom>
          <Standar style={{color: "#183880"}}>
            Un espacio único para descubrir los desarrollos e
            <br />
            innovaciones tecnológicas en este último año.
          </Standar>
        </Typography>
       
        <Wrapper>
          <Image src={image1}></Image>
        </Wrapper>
        <Video />
        <Typography variant="h5" gutterBottom>
          <Registro style={{color: "#183880"}}>¿Cuál será el contenido?</Registro>
        </Typography>
        <Functions />
        <Typography color="textSecondary" variant="h4" gutterBottom>
          <Standar>
            Quiero asistir a un evento único
            <br />
            enfocado a la industria en México.
          </Standar>
        </Typography>
        <Button
          className="App-buttonR"
          type="submit"
          variant="contained"
          color="primary"
         
        >
          <Link to="seccion1">Registrarme</Link>
        </Button>
        <Users />
      </Layout>
    </>
  );
};

export default Home;
