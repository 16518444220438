import React, { useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import styled from "styled-components";
import Typography from "@material-ui/core/Typography";
import "../../App.css";
const Wrapper = styled.div`
  width: 100%;
  margin-top: 5rem;
  margin-bottom: 3rem;
`;
const Bold = styled.b`
  font-weight: 600;
  text-align: center;
`;
const ContentCenter = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
`;
const App = () => {
  const calculateTimeLeft = () => {
    //let year = new Date().getFullYear();
    const difference = +new Date("Aug 25, 2022 16:00:00") - +new Date();
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);
  });

  const timerComponents = [];

  Object.keys(timeLeft).forEach((interval) => {
    if (!timeLeft[interval]) {
      return;
    }
    switch (interval) {
      case "days": {
        timerComponents[0] = timeLeft[interval];
        break;
      }
      case "hours": {
        timerComponents[1] = timeLeft[interval];
        break;
      }
      case "minutes": {
        timerComponents[2] = timeLeft[interval];
        break;
      }
      case "seconds": {
        timerComponents[3] = timeLeft[interval];
        break;
      }
      default: {
        console.log("---");
        break;
      }
    }
  });
  return (
    <Wrapper>
      <ContentCenter>
        <div className="cuenta-header">
          <Bold>CUENTA REGRESIVA</Bold>
        </div>
      </ContentCenter>
      <Grid container spacing={2} justify="center" alignItems="center">
        <Grid item xs={3} sm={1}>
          <div className="App-cuenta">
            <ContentCenter>
              <Typography color="secondary" variant="h4">
                <Bold>{timerComponents[0] ?? 0}</Bold>
              </Typography>
            </ContentCenter>
          </div>
          <div className="cuenta-footer">Días</div>
        </Grid>
        <Grid item xs={3} sm={1}>
          <div className="App-cuenta">
            <ContentCenter>
              <Typography color="secondary" variant="h4">
                <Bold>{timerComponents[1] ?? 0}</Bold>
              </Typography>
            </ContentCenter>
          </div>
          <div className="cuenta-footer">Horas</div>
        </Grid>
        <Grid item xs={3} sm={1}>
          <div className="App-cuenta">
            <ContentCenter>
              <Typography color="secondary" variant="h4">
                <Bold>{timerComponents[2] ?? 0}</Bold>
              </Typography>
            </ContentCenter>
          </div>
          <div className="cuenta-footer">Min.</div>
        </Grid>
        <Grid item xs={3} sm={1}>
          <div className="App-cuenta">
            <ContentCenter>
              <Typography color="secondary" variant="h4">
                <Bold>{timerComponents[3] ?? 0}</Bold>
              </Typography>
            </ContentCenter>
          </div>
          <div className="cuenta-footer">Seg.</div>
        </Grid>
      </Grid>
    </Wrapper>
  );
};

export default App;
