import React from "react";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";

import Logo from "../../../static/images/logo1.webp";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  title: {
    flex: 1,
  },
}));

const Navbar = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <AppBar color="secondary" position="fixed">
        <Toolbar>
          <div className={classes.title}>
            <Avatar alt="ErgoSolar Logo" src={Logo} />
          </div>
          <Button size="small" color="primary">
            ErgoSolar.mx
          </Button>
        </Toolbar>
      </AppBar>
    </div>
  );
};

export default Navbar;
