import React from "react";
import styled from "styled-components";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

import Invitado from "../../../static/images/speaker2.jpg";
import Cliente from "../../../static/images/speaker1.jpg";
import invitado1 from "../../../static/images/invitado1.jpg";
import invitado2 from "../../../static/images/invitado2.jpg";
import invitado3 from "../../../static/images/invitado3.jpg";
import Button from "@material-ui/core/Button";

const Wrapper = styled.div`
  width: 100%;
  margin-top: 3rem;
  margin-bottom: 1.5rem;
`;

const Image = styled.img`
  width: 90%;
  height: auto;
  margin-bottom: 1rem;
`;

const Bold = styled.div`
  font-weight: 500;
  color: #183880;
  padding-bottom: 2rem;
`;

const Ul = styled.ul`
  text-align: left;
  list-style: none;
  margin: 2em;
  padding: 0;

  li {
    margin: 0;
    margin-bottom: 1em;
    padding-left: 1.5em;
    position: relative;

    &:after {
      content: "";
      height: 0.5em;
      width: 0.5em;
      background-image: linear-gradient(
        to right,
        #e9802f,
        #e8762f,
        #e76b30,
        #e66031,
        #e45533
      );
      display: block;
      position: absolute;
      transform: rotate(45deg);
      top: 0.35em;
      left: 0;
    }
  }
`;
const ContentCenter = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 100%;
`;
//25%
const IconFont = styled.img`
  vertical-align: middle;
  width: 35%;
`;
class Users extends React.Component {
  constructor() {
    super();
    this.state = { checked: true, checked2: true };
    this.handleChange = this.handleChange.bind(this);
  }
  handleChange(num, checked, checked2) {
    num === 1 ? this.setState({ checked }) : this.setState({ checked2 });
  }
  handleChange2(checked2) {
    this.setState({ checked2 });
  }

  render() {
    return (
      <>
        <Wrapper>
          {/* <Typography color="textPrimary" variant="h4" gutterBottom>
            <Bold>Speakers</Bold>
          </Typography>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={6}>
              <Image src={Cliente} alt="ErgoSolar Luis Sánchez" />
              <Typography color="textPrimary" variant="h4">
                <Bold>Hans-Joachim Kohlsdorf</Bold>
              </Typography>
              <Ul>
                <li>
                  <Typography
                    color="textSecondary"
                    variant="body1"
                    gutterBottom
                  >
                    Desde Junio 2014 Hans es Socio Director de Energy to Market,
                    E2M, Ciudad de México, empresa comercial y proveedora de
                    servicios en el nuevo Mercado Eléctrico Mayorista. E2M
                    compra energía a empresas de generación eléctrica ayudando
                    así a que estas obtengan financiación y apoya a Usuarios
                    Calificados a obtener electricidad según los requerimientos
                    de sus empresas.
                  </Typography>
                </li>
                <li hidden={this.state.checked}>
                  <Typography
                    color="textSecondary"
                    variant="body1"
                    gutterBottom
                  >
                    Hans ha participado activamente en estructurar proyectos
                    aprovechando las importantes oportunidades que derivan de la
                    des-regulación del sector en su rol de Socio de ZIMMA,
                    Banqueros de Inversión. Él también está activo en el sector
                    de Private Equity y fue Operating Partner de Advent
                    International. Hans es Presidente del Consejo de
                    Construlita/Tecnolite, la empresa líder en iluminación en
                    México y Consejero en otras tres empresas.
                  </Typography>
                </li>
                <li hidden={this.state.checked}>
                  <Typography
                    color="textSecondary"
                    variant="body1"
                    gutterBottom
                  >
                    Él inició su carrera profesional en Siemens en Colombia y
                    fue Presidente de Siemens para la Región Andina y para
                    México y Centro América.
                  </Typography>
                </li>
                <li hidden={this.state.checked}>
                  <Typography
                    color="textSecondary"
                    variant="body1"
                    gutterBottom
                  >
                    Hans estudió Economía en la Universidad de los Andes en
                    Bogotá y el Advanced Management Program en la Harvard
                    Business School.
                  </Typography>
                </li>
                <ContentCenter>
                  <Button
                    type="submit"
                    variant="contained"
                    id="btn-flex"
                    className="btn btn-unique btn-sm"
                    onClick={() =>
                      this.state.checked
                        ? this.handleChange(1, false)
                        : this.handleChange(1, true)
                    }
                  >
                    {this.state.checked ? "Ver más" : "Ver menos"}
                  </Button>
                </ContentCenter>
              </Ul>
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <Image src={Invitado} alt="ErgoSolar ErgoApp Invitado" />
              <Typography color="textPrimary" variant="h4">
                <Bold>Luis Gerardo Sánchez Stone</Bold>
              </Typography>
              <Ul>
                <li>
                  <Typography
                    color="textSecondary"
                    variant="body1"
                    gutterBottom
                  >
                    Propietario fundador de ErgoSolar, una empresa líder en
                    sistemas solares fotovoltaicos y eficiencia energética desde
                    2010, su liderazgo ha llevado a ErgoSolar a ganar el Premio
                    Nacional para el ahorro de energía y a posicionarla dentro
                    de las diez principales compañías de energía solar en
                    México.
                  </Typography>
                </li>
                <li hidden={this.state.checked2}>
                  <Typography
                    color="textSecondary"
                    variant="body1"
                    gutterBottom
                  >
                    Luis Stone, es mexicano de nacimiento y uno de los
                    principales promotores del desarrollo e implementación de
                    energías limpias en el país. Es miembro, presidente y
                    fundador de la Rama Juvenil de la Mecatrónica y miembro
                    fundador de la Asociación Nacional de Energía Solar
                    Puebla-Tlaxcala.
                  </Typography>
                </li>
                <li hidden={this.state.checked2}>
                  <Typography
                    color="textSecondary"
                    variant="body1"
                    gutterBottom
                  >
                    Se graduó del Instituto Tecnológico y de Estudios Superiores
                    de Monterrey campus Guadalajara como ingeniero en
                    mecatrónica además cuenta con una maestría en biomedicina
                    por la Universidad Popular Autónoma del Estado de Puebla y
                    una capacitación en desarrollo ejecutivo por el IESDE School
                    of Management.
                  </Typography>
                </li>
                <li hidden={this.state.checked2}>
                  <Typography
                    color="textSecondary"
                    variant="body1"
                    gutterBottom
                  >
                    En 2007 realizó un intercambio en Suiza y Alemania en la
                    Universidad de Esslingen. Trabajó en la compañía Bosch,
                    logrando el primer giroscopio espacial de 3 ejes del mundo.
                  </Typography>
                </li>
                <li hidden={this.state.checked2}>
                  <Typography
                    color="textSecondary"
                    variant="body1"
                    gutterBottom
                  >
                    Como parte de sus logros profesionales, Luis diseñó el
                    mecanismo y el control de la silla de ruedas "Silla de
                    ruedas de fácil transferencia" con registro de patente y
                    fundó la empresa Medical and Mobility Solutions.
                  </Typography>
                </li>
                <li hidden={this.state.checked2}>
                  <Typography
                    color="textSecondary"
                    variant="body1"
                    gutterBottom
                  >
                    Dentro de sus logros en la industria, ha diseñado dos
                    patentes que respaldaron la fundación de la startup BeeSolar
                    enfocada a democratizar la energía solar en LATAM.
                  </Typography>
                </li>
                <li hidden={this.state.checked2}>
                  <Typography
                    color="textSecondary"
                    variant="body1"
                    gutterBottom
                  >
                    Luis Stone está comprometido con el país, con una fuerte
                    determinación de desarrollar con éxito tecnología nacional
                    sostenible para los mercados mexicano e internacional.
                  </Typography>
                </li>
                <ContentCenter>
                  <Button
                    type="submit"
                    variant="contained"
                    id="btn-flex"
                    className="btn btn-unique btn-sm"
                    onClick={() =>
                      this.state.checked2
                        ? this.handleChange2(false)
                        : this.handleChange2(true)
                    }
                  >
                    {this.state.checked2 ? "Ver más" : "Ver menos"}
                  </Button>
                </ContentCenter>
              </Ul>
            </Grid>
                  </Grid> */ }
       
          <Typography color="textPrimary" variant="h4">
            <Bold >Patrocinador</Bold>
          </Typography> 
          <ContentCenter>
            <IconFont src={invitado1} alt="COPARMEX" />
          
          </ContentCenter>
        </Wrapper>
      </>
    );
  }
}
export default Users;
