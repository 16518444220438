import React from "react";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import Button from "@material-ui/core/Button";
import { createMuiTheme } from "@material-ui/core/styles";

import { withStyles } from "@material-ui/core/styles";
import { IconButton } from "@material-ui/core";

// eslint-disable-next-line no-unused-vars
const theme = createMuiTheme({
  primary: {
    main: "#183880",
    light: "#F76E36",
  },
  secondary: {
    main: "#F76E36",
    light: "#F76E36",
  },
});
const styles = (theme) => ({
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;

  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        ></IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const Terms = ({ isBlue, check }) => {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Link
        color={isBlue ? "primary" : "secondary"}
        variant="body1"
        onClick={handleClickOpen}
      >
        {check
          ? "Términos y condiciones"
          : "Acepto los términos y condiciones."}
      </Link>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle
          color="textPrimary"
          id="customized-dialog-title"
          onClose={handleClose}
        >
          Términos y condiciones
        </DialogTitle>
        <DialogContent dividers>
          <ol>
            <li>
              <Typography color="textSecondary" gutterBottom>
                Los siguientes términos y condiciones son declarados por
                ErgoSolar de México para el uso de la ErgoApp, la aceptación de
                estos significa que ha leído y entendido las reglas, además de
                las políticas de privacidad. Estos términos y condiciones se
                deben de leer cada que entren a la aplicación para verificar
                alguna modificación.
              </Typography>
            </li>
            <li>
              <Typography color="textSecondary" gutterBottom>
                La aplicación permitiera a sus clientes a obtener información
                individual de su proyecto, como lo es su monitoreo, parte en la
                que se encuentra dentro de su proceso, próximos mantenimientos,
                sus referidos, contacto con la empresa, tips de ahorro y
                noticias solares.
              </Typography>
            </li>
            <li>
              <Typography color="textSecondary" gutterBottom>
                Al proporcionar sus datos de contacto, acepta recibir emails de
                la empresa o llamadas y mensajes al número proporcionado con el
                fin de recibir publicidad, promociones e información general de
                la empresa.
              </Typography>
            </li>
            <li>
              <Typography color="textSecondary" gutterBottom>
                La aplicación permitirá al usuario invitado, obtener información
                de precios de energía a lo largo de 10 años, un simulador de
                ahorro de energía, tips de ahorro, noticias solares y datos de
                contacto.
              </Typography>
            </li>
            <li>
              <Typography color="textSecondary" gutterBottom>
                El usuario debe aceptar y cumplir con los términos y condiciones
                que dictamina cada tienda virtual, App Store o Play Store.
              </Typography>
            </li>
            <li>
              <Typography color="textSecondary" gutterBottom>
                Si al momento de descargar la aplicación hay alguna falla se
                debe de verificar si se debe por el dispositivo o es
                directamente del servidor, antes de realizar una queja.
              </Typography>
            </li>
            <li>
              <Typography color="textSecondary" gutterBottom>
                ErgoApp de ErgoSolar no genera un gasto, los cargos de descarga
                o uso de datos son externos a la empresa, eso lo puedes
                consultar con tu proveedor personal de servicios.
              </Typography>
            </li>
            <li>
              <Typography color="textSecondary" gutterBottom>
                Los datos personales ingresados están protegidos y serán usados
                por ErgoSolar. Para ver más detalles consulta las políticas de
                privacidad.
              </Typography>
            </li>
            <li>
              <Typography color="textSecondary" gutterBottom>
                ErgoApp realizará las acciones correspondientes para proteger la
                información y los datos de ésta, por ello se recuerda al usuario
                lo siguiente:
              </Typography>

              <ol type="A">
                <li>
                  <Typography color="textSecondary" gutterBottom>
                    No ingresar códigos o virus que dañen, inhabiliten o afecten
                    el funcionamiento de la aplicación
                  </Typography>
                </li>
                <li>
                  <Typography color="textSecondary" gutterBottom>
                    No proporcionar nombre, correo electrónico o teléfono falso.
                  </Typography>
                </li>
                <li>
                  <Typography color="textSecondary" gutterBottom>
                    Queda prohibido el intento o la realización de alguna
                    modificación dentro de la aplicación ya sea de diseño visual
                    o técnico.
                  </Typography>
                </li>
              </ol>
            </li>
            <li>
              <Typography color="textSecondary" gutterBottom>
                Las ubicaciones mostradas en la sección de comunidad solar son
                únicamente para el uso de identificación de puntos solares,
                ErgoApp de Ergosolar se deslinda de cualquier otro uso que se le
                llegue hacer ya sea ilícito o no.
              </Typography>
            </li>
            <li>
              <Typography color="textSecondary" gutterBottom>
                El monitoreo mostrado expresa el ahorro estimado en base a
                monitoreo que ha habido en el periodo seleccionado, el usuario
                acepta que puede haber variaciones debido a desconexiones de su
                monitoreo, pérdida de comunicación wifi, errores de cobro de
                CFE, etc.
              </Typography>
            </li>
            <li>
              <Typography color="textSecondary" gutterBottom>
                El proceso de la ErgoTrust se actualiza de manera en que se va
                avanzando, no se puede avanzar o saltar a otro paso sino se ha
                autorizado por el líder.
              </Typography>
            </li>
            <li>
              <Typography color="textSecondary" gutterBottom>
                Los artículos dentro de las noticias solares son propiedad de
                ErgoSolar, se pueden utilizar de referencia si se cita de manera
                correcta al autor.
              </Typography>
            </li>
            <li>
              <Typography color="textSecondary" gutterBottom>
                El envío de códigos para sus referidos se debe de hacer de
                manera responsable si se quiere obtener los beneficios, se debe
                entender lo siguiente:
              </Typography>

              <ol type="a">
                <li>
                  <Typography color="textSecondary" gutterBottom>
                    Los referidos deben de usar/presentar su código personal, al
                    descargar la ErgoApp.
                  </Typography>
                </li>
                <li>
                  <Typography color="textSecondary" gutterBottom>
                    Los referidos al redimir el código, automáticamente se
                    registran en la ErgoApp del referenciador.
                  </Typography>
                </li>
                <li>
                  <Typography color="textSecondary" gutterBottom>
                    Para recibir el monto de recompensa, el referido deberá
                    adquirir un proyecto con ErgoSolar.
                  </Typography>
                </li>
                <li>
                  <Typography color="textSecondary" gutterBottom>
                    El monto a recibir es en función al monto de proyecto del
                    referido:
                  </Typography>

                  <ol type="i">
                    <li>
                      <Typography color="textSecondary" gutterBottom>
                        Proyectos de hasta $300,000 pesos mexicanos impuestos
                        incluidos, la recompensa será de $2,500 pesos mexicanos
                        impuestos incluidos.
                      </Typography>
                    </li>
                    <li>
                      <Typography color="textSecondary" gutterBottom>
                        Proyectos entre $300,00 a $1,000,000 pesos mexicanos
                        impuestos incluidos, la recompensa será de $12,000 pesos
                        mexicanos impuestos incluidos.
                      </Typography>
                    </li>
                    <li>
                      <Typography color="textSecondary" gutterBottom>
                        Proyectos de más de $1,000,000 pesos mexicanos impuestos
                        incluidos, la recompensa será de $25,000 pesos mexicanos
                        impuestos incluidos.
                      </Typography>
                    </li>
                  </ol>
                </li>
                <li>
                  <Typography color="textSecondary" gutterBottom>
                    La cantidad de dinero se recibirá en pesos mexicanos a
                    través de una tarjeta de VISA/MASTERCARD. En un lapso de 30
                    días posteriores al cierre de la venta con el referido.
                  </Typography>
                </li>
              </ol>
            </li>
            <li>
              <Typography color="textSecondary" gutterBottom>
                Los servicios proporcionados y materiales (diseño, logo,
                software, tipografía, imágenes, noticias, textos, gráficos,
                tecnología, links) dentro de la ErgoApp son propiedad de
                ErgosSolar México S.A. de C.V. y siguiendo los Derechos de
                Propiedad Intelectual, no se permite copiar, usar, modificar,
                compartir, publicar, exhibir adaptar, editar o reutilizar
                información para algún otro proyecto o situación.
              </Typography>
            </li>
            <li>
              <Typography color="textSecondary" gutterBottom>
                ErgoSolar puede realizar las modificaciones en estos Términos y
                condiciones según sus necesidades, recordando que es
                responsabilidad del usuario de leerlos de manera cotidiana.
              </Typography>
            </li>
            <li>
              <Typography color="textSecondary" gutterBottom>
                El usuario se compromete a usar la aplicación de manera
                correcta, siguiendo los Términos y condiciones de la ErgoApp de
                ErgoSolar, además de otros reglamentos externos, sabiendo que
                existen consecuencias legales si estas no se cumplen.
              </Typography>
            </li>
          </ol>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose} color="primary">
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Terms;
