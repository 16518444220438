import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";

const config = {
  apiKey: "AIzaSyDkec-dFpu1LnqjLAluontxL5ccbSpJ-gc",
  authDomain: "ergosunmeet2022.firebaseapp.com",
  projectId: "ergosunmeet2022",
  storageBucket: "ergosunmeet2022.appspot.com",
  messagingSenderId: "689133500470",
  appId: "1:689133500470:web:96e3cbeee9181060c40097",
  measurementId: "G-KYS5FY456H",

 
};

firebase.initializeApp(config);
firebase.firestore();

export default firebase;
