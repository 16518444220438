import React from "react";
import styled from "styled-components";

import VideoIntro from "../../../static/videos/ergosunmeet2.mp4";

const Wrapper = styled.div`
  width: 100%;
  margin-top: 3rem;
  margin-bottom: 3rem;
`;

const Video = () => {
  return (
    <>
      <Wrapper>
        <video width="95%" height="auto" controls>
          <source src={VideoIntro} type="video/webm" />
        </video>
      </Wrapper>
    </>
  );
};

export default Video;
