import React from "react";
import Layout from "./components/ui/layout/Layout";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams,
} from "react-router-dom";

import Home from "./containers/home/Home";

const App = () => {
  return (
    <Router>
    <div>
     

      <Route path="/" exact>
      <Layout>
        <Home></Home>
      </Layout>
        </Route>

    
        <Route path="/:id" children={<Child />} />
    
    </div>
  </Router>
  );
};



function Child() {
  // We can use the `useParams` hook here to access
  // the dynamic pieces of the URL.
  let { id } = useParams();

  return (
    <div>
      <Layout>
        <Home></Home>
      </Layout>
    </div>
  );
}






export default App;
